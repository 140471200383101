// @import './variables.scss';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

// XXX see which we can take out?
@import '~bulma/sass/components/_all.sass';
@import '~bulma/sass/elements/_all.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/grid/_all.sass';
@import '~bulma/sass/layout/_all.sass';

html,
body,
#root {
  height: 100%;
  background-color: #f8f8f8;
}

::-moz-focus-inner {
  border: 0;
}
